import React, { useState } from 'react';
import './Recipes.css';
import { FaEdit, FaTrash, FaPrint } from 'react-icons/fa'; // Icons for actions
import RecipeModal from './RecipeModal'; // Modal component for adding/editing recipes
import RecipeTable from './RecipeTable'; // Table component for displaying recipes

import recipeData from './testData.json'; 

const Recipes = () => {

    const [recipes, setRecipes] = useState(recipeData.recipes);
    const [selectedRecipe, setSelectedRecipe] = useState(null); // The recipe selected from the table
    const [showModal, setShowModal] = useState(false);
    const [currentRecipe, setCurrentRecipe] = useState(null);

  // Open modal to create a new recipe
  const handleAddRecipe = () => {
    setCurrentRecipe(null); // Clear current recipe (for adding new)
    setShowModal(true);
  };

  // Open modal to edit an existing recipe
  const handleEditRecipe = (recipe) => {
    setCurrentRecipe(recipe); // Pass the selected recipe for editing
    setShowModal(true);
  };

  // Handle saving a new or edited recipe
  const handleSaveRecipe = (recipe) => {
    if (recipe.id) {
      // Edit existing recipe
      setRecipes(recipes.map((r) => (r.id === recipe.id ? recipe : r)));
    } else {
      // Add new recipe
      setRecipes([...recipes, { ...recipe, id: recipes.length + 1 }]);
    }
    setShowModal(false);
  };

  // Handle deleting a recipe
  const handleDeleteRecipe = (id) => {
    setRecipes(recipes.filter((recipe) => recipe.id !== id));
  };

  // Handle selecting a recipe from the table
  const handleRecipeSelect = (recipe) => {
    setSelectedRecipe(recipe); // Display the selected recipe's details on the right
  };

    // Filter ingredients and steps based on the selected recipe
    const selectedRecipeIngredients = selectedRecipe
        ? recipeData.recipe_ingredients.filter((ri) => ri.recipe_id === selectedRecipe.id)
        : [];

    const selectedRecipeSteps = selectedRecipe
        ? recipeData.recipe_steps.filter((step) => step.recipe_id === selectedRecipe.id)
        : [];

  return (
    <div className="recipe-section">
      {/* Left Panel: Recipe Table */}
      <div className="table-container">
        <RecipeTable tableData={recipes} onSelectRecipe={handleRecipeSelect} selectedRecipe={selectedRecipe}/>
      </div>

      {/* Right Panel: Recipe Details */}
      <div className="content-container">
        {selectedRecipe ? (
          <div>
            <h2>{selectedRecipe.name}</h2>
            <p>{selectedRecipe.description}</p>

            {/* Display Ingredients */}
            <h3>Ingredients</h3>
            <ul>
              {selectedRecipeIngredients.map((ingredient, index) => (
                <li key={index}>
                  {ingredient.quantity} {ingredient.unit} {recipeData.ingredients.find((i) => i.id === ingredient.ingredient_id).name}
                  {ingredient.notes ? ` (${ingredient.notes})` : ""}
                </li>
              ))}
            </ul>

            {/* Display Steps */}
            <h3>Steps</h3>
            <ol>
              {selectedRecipeSteps.map((step, index) => (
                <li key={index}>{step.instruction}</li>
              ))}
            </ol>

            <div className="recipe-actions">
              <button onClick={() => console.log("Printing", selectedRecipe)}>
                <FaPrint /> Print
              </button>
              <button onClick={() => handleDeleteRecipe(selectedRecipe.id)}>
                <FaTrash /> Delete
              </button>
            </div>
          </div>
        ) : (
          <p>Select a recipe to see the details</p>
        )}
      </div>

      {/* Recipe Modal for Adding/Editing */}
      {showModal && (
        <RecipeModal
          recipe={currentRecipe}
          onSave={handleSaveRecipe}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default Recipes;