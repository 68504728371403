import React, { useState, useEffect } from 'react';
import './RecipeModal.css';

const RecipeModal = ({ recipe, onSave, onClose, ingredientList }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedIngredients, setSelectedIngredients] = useState([]);

  // Populate the form if editing a recipe
  useEffect(() => {
    if (recipe) {
      setName(recipe.name);
      setDescription(recipe.description);
      setSelectedIngredients(recipe.ingredients || []);
    } else {
      // Reset form if creating new recipe
      setName('');
      setDescription('');
      setSelectedIngredients([]);
    }
  }, [recipe]);

  // Save the recipe and pass it back to parent
  const handleSave = () => {
    const newRecipe = {
      id: recipe?.id || null, // Preserve ID when editing
      name,
      description,
      ingredients: selectedIngredients, // Save selected ingredients
    };
    onSave(newRecipe); // Trigger save in parent component
  };

  // Handle ingredient selection
  const handleIngredientSelect = (ingredient) => {
    if (!selectedIngredients.includes(ingredient)) {
      setSelectedIngredients([...selectedIngredients, ingredient]);
    }
  };

  // Remove ingredient from selected list
  const handleRemoveIngredient = (ingredient) => {
    setSelectedIngredients(selectedIngredients.filter((ing) => ing !== ingredient));
  };

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <h2>{recipe ? 'Edit Recipe' : 'Add New Recipe'}</h2>

        {/* Recipe Name */}
        <div className="form-group">
          <label>Recipe Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        {/* Recipe Description */}
        <div className="form-group">
          <label>Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>

        {/* Ingredient Selection */}
        <div className="form-group">
          <label>Select Ingredients</label>
          <div className="ingredient-selector">
            {ingredientList.map((ingredient) => (
              <button
                key={ingredient.id}
                onClick={() => handleIngredientSelect(ingredient.name)}
              >
                {ingredient.name}
              </button>
            ))}
          </div>
          <div className="selected-ingredients">
            <h4>Selected Ingredients</h4>
            {selectedIngredients.map((ingredient, index) => (
              <div key={index} className="selected-ingredient">
                <span>{ingredient}</span>
                <button onClick={() => handleRemoveIngredient(ingredient)}>Remove</button>
              </div>
            ))}
          </div>
        </div>

        {/* Modal Actions */}
        <div className="modal-actions">
          <button onClick={handleSave}>Save</button>
          <button onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default RecipeModal;
