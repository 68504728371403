import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './KitchenDashboard.css';
import Recipes from '../Recipes/Recipes';
import Scheduling from '../Scheduling/Scheduling';
import ProductionSchedule from '../Production/ProductionSchedule.js';

import { FaUtensils, FaCalendarAlt, FaClipboardList, FaHome } from 'react-icons/fa'; // Icons from react-icons

const KitchenDashboard = () => {
    const [activeSection, setActiveSection] = useState('Recipes');
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);
    const navigate = useNavigate();

    const handleSectionClick = (section) => {
      setActiveSection(section);
      setIsSidebarCollapsed(true); // Collapse sidebar when an option is clicked
    };
  
    const handleMouseEnter = () => {
      setIsSidebarCollapsed(false); // Expand sidebar on hover
    };
  
    const handleMouseLeave = () => {
      setIsSidebarCollapsed(true); // Collapse sidebar when mouse leaves
    };

    const renderContent = () => {
        switch (activeSection) {
        case 'Recipes':
            return <Recipes />;
        case 'Scheduling':
            return <Scheduling />;
        case 'ProductionSchedule':
            return <ProductionSchedule />;
        default:
            return <Recipes />;
        }
    };

    const goHome = () => {
        navigate('/'); // Navigate back to the landing page
      };

    return (
        <div className="dashboard">
              <aside
        className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
            
            <ul>
            <li onClick={() => handleSectionClick('Recipes')} className={activeSection === 'Recipes' ? 'active' : ''}><FaUtensils className="icon" />
            {!isSidebarCollapsed && <span>Recipes</span>}</li>
            <li onClick={() => handleSectionClick('Scheduling')} className={activeSection === 'Scheduling' ? 'active' : ''}><FaCalendarAlt className="icon" />
            {!isSidebarCollapsed && <span>Schedule</span>}</li>
            <li onClick={() => handleSectionClick('ProductionSchedule')} className={activeSection === 'ProductionSchedule' ? 'active' : ''}><FaClipboardList className="icon" />
            {!isSidebarCollapsed && <span>Production</span>}</li>
            </ul>

            <div className="sidebar-footer" onClick={goHome}>
                <FaHome className="icon" /> {/* Home Icon */}
                {!isSidebarCollapsed && <span>Home</span>}
            </div>
        </aside>

        <div className="main-content">
            <div className="content-area">
            {renderContent()}
            </div>
        </div>

        </div>
    );
};

export default KitchenDashboard;
