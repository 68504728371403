// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.login-form {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    text-align: center;
}

.login-form h2 {
    margin-bottom: 1rem;
}

.login-form input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-form button {
    padding: 0.5rem 1rem;
    width: 100%;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.login-form button:hover {
    background-color: #0056b3;
}

.error {
    color: red;
    margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;IACf,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB","sourcesContent":[".login-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    background-color: #f5f5f5;\n}\n\n.login-form {\n    background-color: white;\n    padding: 2rem;\n    border-radius: 8px;\n    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);\n    width: 300px;\n    text-align: center;\n}\n\n.login-form h2 {\n    margin-bottom: 1rem;\n}\n\n.login-form input {\n    width: 100%;\n    padding: 0.5rem;\n    margin-bottom: 1rem;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n}\n\n.login-form button {\n    padding: 0.5rem 1rem;\n    width: 100%;\n    border: none;\n    background-color: #007bff;\n    color: white;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.login-form button:hover {\n    background-color: #0056b3;\n}\n\n.error {\n    color: red;\n    margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
