import React, { useState } from 'react';
import './Ingredients.css'; // Separate CSS for Ingredients
import { FaEdit, FaTrash } from 'react-icons/fa'

import './RecipeTable.css';

const RecipeTable = ({tableData, onSelectRecipe, selectedRecipe}) => {

    const [recipeData, setTableData] = useState(tableData);

    return (
        
        <div className="recipe-list">
            {recipeData.map((recipe) => (
                <div
                className={`recipe-item ${selectedRecipe && selectedRecipe.id === recipe.id ? 'selected' : ''}`}
                onClick={() => onSelectRecipe(recipe)} >

                    <div className="recipe-info">
                        <h3>{recipe.name}</h3>
                    </div>
                    <div className="recipe-actions">
                        <p>{recipe.description}</p>
                    </div>
                </div>
            ))}
        </div>

    );


};

export default RecipeTable;
