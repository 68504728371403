import React, { useState, useContext } from 'react';
import './Login.css'; 
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthProvider';

const Login = () => {
    const { login } = useContext(AuthContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setErrorMessage('');
    
        try {
            const response = await fetch('http://localhost:5000/api/users/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                alert(data.access_token)
                // Save the token and user role in local storage
                login(data.access_token)
      
                navigate('/');  // Redirect after login
            } else {
                setErrorMessage(data.message || 'Invalid credentials');
            }
        } catch (error) {
            setErrorMessage('Failed to connect to the backend. Please try again later.');
        }
    };

    return (    
        <div className="login-container">
            <form className="login-form" onSubmit={handleLogin}>
                <h2>Login</h2>
                {errorMessage && <p className="error">{errorMessage}</p>}
                <input
                    type="text"
                    placeholder="Username"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Login</button>
            </form>
        </div>
    );
};

export default Login;
