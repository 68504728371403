import logo from './logo.svg';
import React, { useContext, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import Login from './routes/Login/Login';
import LandingPage from './routes/LandingPage/LandingPage';
import KitchenDashboard from './routes/Kitchen/Dashboard/KitchenDashboard'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import CreateUser from './routes/Login/CreateUser';
import AdminDashboard from './routes/Admin/AdminDashboard';
import { AuthContext, AuthProvider } from './AuthProvider';

function App() {

    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    
                    <Route path="login" element={<Login />} />
                    <Route path="create_user" element={
                        <ProtectedRoute element={<CreateUser />}/> 
                    }/>
                    <Route path='admin_dashboard' element={<AdminDashboard />}/>

                    
                    <Route path='/' element={
                        <ProtectedRoute element={<LandingPage />} />
                    }/>
                    
    
                    <Route path="kitchen" element={
                        <ProtectedRoute element={<KitchenDashboard />}/>
                    }/> 



                    {/* Catch-all Route for undefined paths */}
                    <Route path="*" element={<Navigate to="/" />} />

                    {/* <Route path="kitchen" element={} /> */}
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    )
};
export default App;
