import React, { useState, useEffect } from 'react';

const ManageLocations = () => {
    const [locations, setLocations] = useState([]);
    const [newLocation, setNewLocation] = useState('');

    useEffect(() => {
        // Fetch locations from API and setLocations
    }, []);

    const handleAddLocation = async () => {
        // Add new location to the database
    };

    const handleUpdateLocation = async (id) => {
        // Update location with given ID
    };

    const handleDeleteLocation = async (id) => {
        // Delete location with given ID
    };

    return (
        <div>
            <h2>Locations</h2>
            <table>
                <thead>
                    <tr>
                        <th>Location Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {locations.map(location => (
                        <tr key={location.id}>
                            <td>{location.name}</td>
                            <td>
                                <button onClick={() => handleUpdateLocation(location.id)}>Edit</button>
                                <button onClick={() => handleDeleteLocation(location.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <h3>Add New Location</h3>
            <input type="text" placeholder="Location Name" value={newLocation} onChange={(e) => setNewLocation(e.target.value)} />
            <button onClick={handleAddLocation}>Add Location</button>
        </div>
    );
};

export default ManageLocations;
