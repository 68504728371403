import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CreateUser = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('user');  // You can also manage roles like 'admin'
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    // Function to handle user creation
    const handleCreateUser = async (e) => {
        e.preventDefault();
        setErrorMessage('');

        try {
            const token = localStorage.getItem('token');  // Assuming token is stored on login

            const response = await fetch('http://localhost:5000/api/users/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`  // Pass JWT token
                },
                body: JSON.stringify({ username, password, role }),
            });

            const data = await response.json();

            if (response.ok) {
                navigate('/dashboard');  // Redirect after user creation
            } else {
                setErrorMessage(data.message || 'Error creating user');
            }
        } catch (error) {
            setErrorMessage('Failed to create user. Please try again later.');
        }
    };

    // Assuming you already check if the user is authorized (admin) before rendering this component
    return (
        <div className="create-user-container">
            <form className="create-user-form" onSubmit={handleCreateUser}>
                <h2>Create New User</h2>
                {errorMessage && <p className="error">{errorMessage}</p>}
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <select value={role} onChange={(e) => setRole(e.target.value)}>
                    <option value="user">User</option>
                    <option value="admin">Admin</option>
                </select>
                <button type="submit">Create User</button>
            </form>
        </div>
    );
};

export default CreateUser;
