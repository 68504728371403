// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Full height for the html, body, and app container */
  html, body {
    height: 100%;
    font-family: 'Arial', sans-serif;
    overflow: auto; /* Allow scrolling for smaller screens */
  }
  
  #root, .App {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;EACxB;;EAEA,sDAAsD;EACtD;IACE,YAAY;IACZ,gCAAgC;IAChC,cAAc,EAAE,wCAAwC;EAC1D;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;EACxB","sourcesContent":["/* Global reset */\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n  }\n  \n  /* Full height for the html, body, and app container */\n  html, body {\n    height: 100%;\n    font-family: 'Arial', sans-serif;\n    overflow: auto; /* Allow scrolling for smaller screens */\n  }\n  \n  #root, .App {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
