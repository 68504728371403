import React, { useState, useEffect } from 'react';

const ManageUsers = () => {
    const [users, setUsers] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [newUser, setNewUser] = useState({ username: '', email: '', password: ''});
    const [editingUser, setEditingUser] = useState(null);

    // Fetch users and permissions on component load
    useEffect(() => {
        fetchUsers();
        fetchPermissions();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await fetch('/api/users');
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const fetchPermissions = async () => {
        try {
            const response = await fetch('/api/permissions');
            const data = await response.json();
            setPermissions(data);
        } catch (error) {
            console.error("Error fetching permissions:", error);
        }
    };

    const handleAddUser = async () => {
        try {
            const response = await fetch('http://localhost:5000/api/users/create', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newUser),
            });
            if (response.ok) {
                fetchUsers(); // Refresh the list after adding
                setNewUser({ username: '', email: '', password: '' });
            } else {
                alert("Failed to add user.");
            }
        } catch (error) {
            console.error("Error adding user:", error);
        }
    };

    const handleEditUser = async (userId) => {
        try {
            const response = await fetch(`/api/users/${userId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(editingUser),
            });
            if (response.ok) {
                fetchUsers(); // Refresh the list after editing
                setEditingUser(null); // Exit edit mode
            } else {
                alert("Failed to edit user.");
            }
        } catch (error) {
            console.error("Error editing user:", error);
        }
    };

    const handleDeleteUser = async (userId) => {
        try {
            await fetch(`/api/users/${userId}`, { method: 'DELETE' });
            fetchUsers(); // Refresh the list after deletion
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    return (
        <div>
            <h2>Current Users</h2>
            <table>
               
                <tbody>
                    {users.map(user => (
                        <tr key={user.id}>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.permission}</td>
                            <td>
                                <button onClick={() => setEditingUser(user)}>Edit</button>
                                <button onClick={() => handleDeleteUser(user.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <h3>Add New User</h3>
            <input type="text" placeholder="Username" value={newUser.name} onChange={(e) => setNewUser({ ...newUser, username: e.target.value })} />
            <input type="email" placeholder="Email" value={newUser.email} onChange={(e) => setNewUser({ ...newUser, email: e.target.value })} />
            <input type="password" placeholder="Password" value={newUser.password} onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} />
            <select value={newUser.permission} onChange={(e) => setNewUser({ ...newUser, permission: e.target.value })}>
                <option value="">Select Permission</option>
                {permissions.map(permission => (
                    <option key={permission.id} value={permission.name}>{permission.name}</option>
                ))}
            </select>
            <button onClick={handleAddUser}>Add User</button>

            {editingUser && (
                <div className="edit-section">
                    <h3>Edit User</h3>
                    <input type="text" placeholder="Name" value={editingUser.name} onChange={(e) => setEditingUser({ ...editingUser, name: e.target.value })} />
                    <input type="email" placeholder="Email" value={editingUser.email} onChange={(e) => setEditingUser({ ...editingUser, email: e.target.value })} />
                    <select value={editingUser.permission} onChange={(e) => setEditingUser({ ...editingUser, permission: e.target.value })}>
                        <option value="">Select Permission</option>
                        {permissions.map(permission => (
                            <option key={permission.id} value={permission.name}>{permission.name}</option>
                        ))}
                    </select>
                    <button onClick={() => handleEditUser(editingUser.id)}>Save Changes</button>
                    <button onClick={() => setEditingUser(null)}>Cancel</button>
                </div>
            )}
        </div>
    );
};

export default ManageUsers;
