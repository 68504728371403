// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ingredients-section {
    padding: 2rem;
    background-color: #f4f4f4; /* Soft matte background */
  }
  
  .ingredients-list {
    margin-bottom: 2rem;
  }
  
  .ingredient-item {
    background-color: #e0e0e0;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
  }
  
  .add-ingredient {
    display: flex;
    gap: 1rem;
  }
  
  .add-ingredient input {
    flex-grow: 1;
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #f4f4f4;
  }
  
  .add-ingredient button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .add-ingredient button:hover {
    background-color: #5a6268;
  }
  
  .ingredient-item button {
    background-color: #dc3545; /* Red for delete */
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 0.5rem 1rem;
  }
  
  .ingredient-item button:hover {
    background-color: #c82333;
  }
  `, "",{"version":3,"sources":["webpack://./src/routes/Kitchen/Recipes/Ingredients.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB,EAAE,0BAA0B;EACvD;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,qBAAqB;IACrB,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,SAAS;EACX;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,oBAAoB;IACpB,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB,EAAE,mBAAmB;IAC9C,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,oBAAoB;EACtB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".ingredients-section {\n    padding: 2rem;\n    background-color: #f4f4f4; /* Soft matte background */\n  }\n  \n  .ingredients-list {\n    margin-bottom: 2rem;\n  }\n  \n  .ingredient-item {\n    background-color: #e0e0e0;\n    padding: 1rem;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    margin-bottom: 0.5rem;\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .add-ingredient {\n    display: flex;\n    gap: 1rem;\n  }\n  \n  .add-ingredient input {\n    flex-grow: 1;\n    padding: 0.5rem;\n    border-radius: 4px;\n    border: 1px solid #ccc;\n    background-color: #f4f4f4;\n  }\n  \n  .add-ingredient button {\n    background-color: #6c757d;\n    color: white;\n    border: none;\n    padding: 0.5rem 1rem;\n    cursor: pointer;\n    border-radius: 4px;\n  }\n  \n  .add-ingredient button:hover {\n    background-color: #5a6268;\n  }\n  \n  .ingredient-item button {\n    background-color: #dc3545; /* Red for delete */\n    color: white;\n    border: none;\n    cursor: pointer;\n    border-radius: 4px;\n    padding: 0.5rem 1rem;\n  }\n  \n  .ingredient-item button:hover {\n    background-color: #c82333;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
