import React from 'react';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <div className="main-content">
      <h1>Explore Our Sections</h1>
      <div className="card-grid">
        <Card 
          title="Kitchen" 
          description="Discover the heart of our culinary creations."
          link="/kitchen"
        />
        <Card 
          title="Pastry" 
          description="Indulge in our sweet and delicate pastries."
          link="/pastry"
        />
        <Card 
          title="Bread" 
          description="Freshly baked bread straight from the oven."
          link="/bread"
        />
        {/* Placeholder Cards */}
        <Card 
          title="Admin" 
          description="Admin Dashboard"
          link="/admin_dashboard"
        />
        <Card 
          title="Section 5" 
          description="More information coming soon."
          link="#"
        />
        <Card 
          title="Section 6" 
          description="Stay tuned for updates."
          link="#"
        />
      </div>
    </div>
  );
};

const Card = ({ title, description, link }) => {
  return (
    <a href={link} className="card">
      <div className="card-content">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </a>
  );
};

export default LandingPage;
