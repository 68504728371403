import React, { useState } from 'react';
import './AdminDashboard.css';
import ManageUsers from './ManageUsers';
import ManageLocations from './ManageLocations';
import ManageRoles from './ManageRoles';

const AdminDashboard = () => {
    const [activeSection, setActiveSection] = useState('users');

    return (
        <div className="admin-dashboard">
            <aside className="sidebar">
                <h2>Admin Dashboard</h2>
                <ul>
                    <li onClick={() => setActiveSection('users')} className={activeSection === 'users' ? 'active' : ''}>Users</li>
                    <li onClick={() => setActiveSection('locations')} className={activeSection === 'locations' ? 'active' : ''}>Locations</li>
                    <li onClick={() => setActiveSection('roles')} className={activeSection === 'roles' ? 'active' : ''}>Roles</li>
                </ul>
            </aside>

            <main className="main-content">
                <header>
                    <h1>Manage {activeSection.charAt(0).toUpperCase() + activeSection.slice(1)}</h1>
                </header>

                {activeSection === 'users' && <ManageUsers />}
                {activeSection === 'locations' && <ManageLocations />}
                {activeSection === 'roles' && <ManageRoles />}
            </main>
        </div>
    );
};

export default AdminDashboard;
