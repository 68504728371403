// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true); // Track loading state

    useEffect(() => {
        const token = localStorage.getItem('access_token');
        if (token) {
            checkAuthStatus(token);
        } else {
            setLoading(false); // Stop loading if no token
        }
    }, []);

    const login = (token) => {
        localStorage.setItem('access_token', token); // Store JWT
        setIsAuthenticated(true);

    };

    const logout = () => {
        localStorage.removeItem('access_token');
        setIsAuthenticated(false);
    };

    const checkAuthStatus = async (token) => {
        try {
            const response = await fetch('http://localhost:5000/api/users/check_token', {
                method: 'GET',
                headers: { 'Authorization': `Bearer ${token}` },
            });

            if (response.ok) {
                // const data = await response.json();
                const data = await response.json();
                setIsAuthenticated(true);
                // setUser({ user_id: data.user_id });
            } else {
                logout();
            }
        } catch (error) {
            console.error("Error checking auth status:", error);
            logout();
        } finally {
            setLoading(false); // Stop loading once the check is complete
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};
