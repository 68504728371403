import React, { useState, useEffect } from 'react';
import './ProductionSchedule.css';
import recipeData from '../Recipes/testData.json'; // Import recipes from the testData.json file

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const ProductionSchedule = () => {
  const [selectedDate, setSelectedDate] = useState(new Date()); // Current selected date
  const [days, setDays] = useState([]); // Days to render in the calendar
  const [currentMonth, setCurrentMonth] = useState(selectedDate.getMonth());
  const [currentYear, setCurrentYear] = useState(selectedDate.getFullYear());

  const [scheduledRecipes, setScheduledRecipes] = useState({}); // Store scheduled recipes by date
  const [selectedRecipe, setSelectedRecipe] = useState('');
  const [quantity, setQuantity] = useState(1);

  const recipes = recipeData.recipes; // Get recipes from the imported JSON file

  // Helper function to get the number of days in the current month
  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate(); // Get total days in the current month
  };

  // Helper function to get the starting day of the week for the month
  const getStartDayOfMonth = (month, year) => {
    return new Date(year, month, 1).getDay(); // Get day of the week the 1st of the month falls on
  };

  // Update the calendar days whenever the month/year changes
  useEffect(() => {
    const numDays = getDaysInMonth(currentMonth, currentYear);
    const startDay = getStartDayOfMonth(currentMonth, currentYear);
    
    // Create an array with empty slots for days before the start day
    const daysArray = Array.from({ length: startDay }, () => null).concat(
      Array.from({ length: numDays }, (_, i) => i + 1)
    );
    setDays(daysArray);
  }, [currentMonth, currentYear]);

  // Handle selecting a day
  const handleDateSelect = (day) => {
    setSelectedDate(new Date(currentYear, currentMonth, day));
  };

  // Navigate to the previous month
  const handlePreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  // Navigate to the next month
  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  // Handle adding a recipe to the schedule
  const handleAddRecipe = () => {
    const dateKey = selectedDate.toDateString(); // Unique key for each date

    // Update the scheduled recipes for the selected date
    setScheduledRecipes((prev) => ({
      ...prev,
      [dateKey]: [...(prev[dateKey] || []), { recipeId: selectedRecipe, quantity }]
    }));

    // Reset form
    setSelectedRecipe('');
    setQuantity(1);
  };

  // Get scheduled recipes for the selected date
  const scheduledRecipesForSelectedDate = scheduledRecipes[selectedDate.toDateString()] || [];

  return (
    <div className="schedule-container">
      {/* Calendar View */}
      <div className="calendar-container">
        <div className="calendar-header">
          <button onClick={handlePreviousMonth}>&lt;</button>
          <span>{new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long' })} {currentYear}</span>
          <button onClick={handleNextMonth}>&gt;</button>
        </div>
        <div className="calendar-grid">
          {daysOfWeek.map((day) => (
            <div key={day} className="calendar-day-header">{day}</div>
          ))}
          {days.map((day, index) => (
            <div
              key={index}
              className={`calendar-day ${day === selectedDate.getDate() && currentMonth === selectedDate.getMonth() && currentYear === selectedDate.getFullYear() ? 'selected' : ''}`}
              onClick={() => day && handleDateSelect(day)}
            >
              {day || ''}
            </div>
          ))}
        </div>
        
      </div>

      {/* Taskbar/Sidebar */}
      <div className="taskbar-container">
        <div className="taskbar-content">
          <h3>Scheduled Recipes for {selectedDate.toDateString()}</h3>
          <ul>
            {scheduledRecipesForSelectedDate.length > 0 ? (
              scheduledRecipesForSelectedDate.map((item, index) => (
                <li key={index}>
                  {recipes.find((recipe) => recipe.id === parseInt(item.recipeId))?.name} (Quantity: {item.quantity})
                </li>
              ))
            ) : (
              <li>No recipes scheduled for this date.</li>
            )}
          </ul>

          <h3>Add Recipe</h3>
          <select value={selectedRecipe} onChange={(e) => setSelectedRecipe(e.target.value)}>
            <option value="">Select Recipe</option>
            {recipes.map((recipe) => (
              <option key={recipe.id} value={recipe.id}>
                {recipe.name}
              </option>
            ))}
          </select>
          <input
            type="number"
            placeholder="Quantity"
            value={quantity}
            min="1"
            onChange={(e) => setQuantity(e.target.value)}
          />
          <button onClick={handleAddRecipe} disabled={!selectedRecipe}>
            Add to Schedule
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductionSchedule;
